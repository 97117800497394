import React, {Component} from 'react';
import './App.css';
import Typed from 'react-typed';

class App extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showContact: false,
        };
    }

    render() {
        const self = this;
        return (
            <div className="App">
                <header className="App-header">
                    <Typed
                        strings={["jarrod drinks `☕`", "jarrod loves `👫`", "jarrod listens `🎷`", "jarrod reads `📚`",
                            "jarrod walks `🐾`", "jarrod codes `💻`"]}
                        typeSpeed={50}
                        smartBackspace
                        backSpeed={25}
                        backDelay={1000}
                        onComplete={
                            function () {
                                self.setState({
                                    showComponent: true,
                                })
                            }}
                    />
                    <div>
                        {this.state.showComponent ?
                            null :
                            null
                        }
                    </div>
                    <br/>
                </header>
            </div>
        );
    }
}

export default App;
